<template>
    <div class="crossword" v-if="crossword">

        <h1>{{ crossword.name }}</h1>

        <div class="message" v-if="errorMessage != null">
            <div>
                <div class="error" v-html="errorMessage"></div>
                <button @click="errorMessage = null">Kliknij i spróbuj ponownie</button>
            </div>
        </div>

        <div class="message" v-if="message != null">
            <div>
                <div class="success" v-html="message">{{ message }}</div>
                <router-link to="/dashboard" tag="button">Wróć do listy</router-link>
            </div>
        </div>

        <div class="question" v-if="question != null">
            {{ question }}
            <span @click="close_question();" class="close"></span>
        </div>

        <div class="crossword-wrapper" v-if="errorMessage == null && message == null">

            <div class="item">

                <div v-for="(item,index) in crossword.questions" :key="item.id" :class="'offset-' + item.position + ' ' + ((item.showSpace) ? ' has-space' : '')">
                    <div class="char" v-for="(char, char_index) in item.answer" :key="item.id + '_' + char_index">
                        <span @click="show_question(item.question);" class="no" v-if="char_index == 0">
                            {{ index+1 }}
                            <span class="answer">
                                {{ item.question }}
                            </span>
                        </span>
                        <input @input="nextItem($event);" @keydown="removeChar($event);" type="text" :id="'item_' + index + '_' + char_index" :ref="'item_' + index + '_' + char_index" v-model="answers[parseInt(index)][parseInt(char_index)]" maxlength="1" :disabled="char_index == item.space" :class="getClass(char_index, index, item)" />
                    </div>
                </div>
            </div>

            <button class="button" @click="save">Zatwierdź</button>

        </div>

    </div>
</template>


<script>

    import { model } from "../model.js";

    export default {
        name: 'Crossword',
        data() {
            return {
                'errors': [],
                'errorMessage': null,
                'message': null,
                'refsList': [],
                'letters': 0,
                'answers': [],
                'question': null,
                'currentQuestion': null,
                'crossword': null,
                'position': 0,
                'corrects': {}
            }
        },

        mounted() {
            this.init();
        },
        methods: {

            close_question: function()
            {
                this.question = null;
            },

            show_question: function(question)
            {
                this.question = question;
            },

            getRefs: function()
            {
                var items = [];
                for (var i in this.$refs)
                {
                    items.push(i);
                }

                this.refsList = items;
            },

            removeChar: function(event)
            {
                this.getRefs();

                if (event.key === "Backspace" || event.key === "Delete")
                {
                    event.target.value = "";

                    var name = event.target.id;
                    var tmp = name.split('_');
                    this.answers[parseInt(tmp[1])][parseInt(tmp[2])] = '';


                    var index = this.refsList.indexOf(name);
                    //var nextIndex = (index + 1) % this.refsList.length;
                    var prevIndex = (index - 1) % this.refsList.length;

                    var prev = this.refsList[prevIndex];

                    if (this.$refs[prev])
                    {
                        if (this.$refs[prev][0].disabled)
                        {
                            prev = this.refsList[prevIndex-1];
                        }

                        this.$refs[prev][0].focus();
                    }





                    console.log('delete');
                    return event.preventDefault();
                }


            },

            nextItem: function(event)
            {
                this.getRefs();

                var name = event.target.id;

                var index = this.refsList.indexOf(name);
                var nextIndex = (index + 1) % this.refsList.length;

                var next = this.refsList[nextIndex];

                if (this.$refs[next])
                {
                    if (this.$refs[next][0].disabled)
                    {
                        next = this.refsList[nextIndex+1];
                    }

                    this.$refs[next][0].focus();
                }

            },

            save: async function() {

                let data = {};
                this.errors = [];

                for (var i in this.answers)
                {
                    data[i] = '';

                    for (var a = 0; a < this.answers[i].length; a++)
                    {
                        if (this.answers[i][a])
                        {
                            data[i] += this.answers[i][a].toString();
                        }
                        else
                        {
                            data[i] += ' ';
                        }

                        data[i] = data[i].replace(',,', ' ');
                        data[i] = data[i].replace(',', '');
                    }
                }

                var id = this.$route.params.id;
                var response = await model.saveCrossword(id, {"answers": data});

                if (response.data.status == 'Error')
                {
                    for (let i in data)
                    {
                        if (data[i].toLowerCase() != this.corrects[i].toLowerCase())
                        {
                            this.answers[i] = [];

                            this.errors.push(i);
                        }
                    }

                    this.errorMessage = response.data.message;
                }
                else
                {
                    this.letters = 1;

                    var container = this;

                    setTimeout(function() {
                        container.message = response.data.message;
                    }, 5000);

                }


                return false;

            },

            getClass: function(index, row_index, item)
            {
                let name = '';

                if (index == item.answer_letter-1)
                {
                    if (this.letters)
                    {
                        name += ' is-success';
                    }
                    else
                    {
                        name += ' cross-answer';
                    }
                }

                if (this.errors.indexOf(row_index.toString()) >= 0)
                {
                    name += ' is-error';
                }

                return name;

            },

            init: async function() {

                var id = this.$route.params.id;

                var response = await model.getCrossword(id);

                if (response.data.status == 'Error')
                {
                    localStorage.removeItem('token');
                    this.$router.push({ name: 'Home' });
                    setTimeout(function() {
                        location.reload();
                    }, 100);
                }

                this.crossword = response.data.results;

                console.log(this.crossword);

                for (let i in this.crossword.questions)
                {
                    var index = parseInt(i);

                    let item = this.crossword.questions[i];

                    if (item.answer_letter > this.position)
                    {
                        this.position = item.answer_letter;
                    }

                    this.corrects[index] = this.crossword.questions[i].answer;
                }

                for (let i in this.crossword.questions)
                {
                    let item = this.crossword.questions[i];
                    var offset = this.position - (item.answer_letter);

                    this.crossword.questions[i].position = offset;
                    this.crossword.questions[i].space = this.crossword.questions[i].answer.indexOf(" ");

                    this.crossword.questions[i].answer = item.answer.split('');

                    this.answers.push([]);
                }
            }

        }
    }
</script>
